<template>
  <!-- 店铺保证金 -->
  <el-card class="box-card income">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">店铺保证金</h3>
    </div>
    <div class="box">
      <div class="top">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="用户名称">
            <el-input
              placeholder="请输入用户名称"
              size="mini"
              v-model="form.user_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店名称">
            <el-input
              placeholder="请输入门店名称"
              size="mini"
              v-model="form.store_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店ID">
            <el-input
              placeholder="请输入门店ID"
              size="mini"
              v-model="form.store_id"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店入驻单号">
            <el-input
              placeholder="请输入门店入驻单号"
              size="mini"
              v-model="form.store_bond_no"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店电话">
            <el-input
              placeholder="请输入门店电话"
              size="mini"
              v-model="form.phone"
            ></el-input>
          </el-form-item>
          <el-form-item label="退出入驻单号">
            <el-input
              placeholder="请输入门店退出入驻单号"
              size="mini"
              v-model="form.partner_trade_no"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付时间">
            <el-date-picker
              v-model="TimeValue"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              size="mini"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="退出打款时间">
            <el-date-picker
              v-model="TimeOutValue"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              size="mini"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio v-model="form.status" :label="1">入驻</el-radio>
            <el-radio v-model="form.status" :label="5">申请退出</el-radio>
            <el-radio v-model="form.status" :label="10">退出</el-radio>
          </el-form-item>
          <el-form-item label="是否入驻">
            <el-radio v-model="form.is_settle_in" :label="1">已入驻</el-radio>
            <el-radio v-model="form.is_settle_in" :label="2">未入驻</el-radio>
            <el-radio v-model="form.is_settle_in" :label="3">已退出</el-radio>
          </el-form-item>
        </el-form>
        <el-button size="mini" type="primary" @click="search">筛选</el-button>
        <el-button type="success" size="small" plain @click="exportShop"
          >导出</el-button
        >
      </div>
      <el-table
        height="570"
        border
        size="mini"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="store_id" label="门店ID"> </el-table-column>
        <el-table-column prop="store_name" label="门店名称"> </el-table-column>
        <el-table-column prop="store_phone" label="门店电话"> </el-table-column>
        <el-table-column prop="user_name" label="用户名称"></el-table-column>
        <el-table-column prop="store_bond_no" label="门店入驻单号">
        </el-table-column>
        <el-table-column prop="transaction_id" label="支付单号">
        </el-table-column>
        <el-table-column prop="money" label="金额"> </el-table-column>
        <el-table-column label="状态">
          <div class="fyslot" slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" size="mini" type="info"
              >入驻</el-tag
            >
            <el-tag v-if="scope.row.status == 5" size="mini" type="info"
              >申请退出</el-tag
            >
            <el-tag v-if="scope.row.status == 10" size="mini" type="info"
              >退出</el-tag
            >
          </div>
        </el-table-column>
        <el-table-column prop="is_settle_in" label="是否已入驻">
          <div class="fyslot" slot-scope="scope">
            <el-tag v-if="scope.row.is_settle_in == 1" size="mini" type="info"
              >已入驻</el-tag
            >
            <el-tag v-if="scope.row.is_settle_in == 2" size="mini" type="info"
              >未入驻</el-tag
            >
            <el-tag v-if="scope.row.is_settle_in == 3" size="mini" type="info"
              >已退出</el-tag
            >
          </div>
        </el-table-column>
        <el-table-column label="支付时间">
          <template slot-scope="scope">
             <div>{{scope.row.settle_in_time ? scope.row.settle_in_time : '暂无相关信息'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="审核备注">
        </el-table-column>
        <el-table-column prop="out_remark" label="退出门店理由">
        </el-table-column>
        <el-table-column prop="pay_out_remark" label="打款扣除金额原因">
        </el-table-column>
        <el-table-column prop="partner_trade_no" label="门店退出入驻单号">
        </el-table-column>
        <el-table-column prop="refund_money" label="退出金额">
        </el-table-column>
        <el-table-column label="退出打款时间">
          <template slot-scope="scope">
             <div>{{scope.row.refund_pay_time ? scope.row.refund_pay_time : '暂无相关信息'}}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="out_time" label="退出时间"> </el-table-column> -->
        <el-table-column label="操作">
          <div class="caozuo" slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              round
              @click="shenhe(scope.row.id, scope.row.store_name, scope.row.out_remark)"
              v-if="scope.row.status == 5"
              >退出审核</el-button>
              <el-button
              size="mini"
              type="primary"
              round
              @click="toPlayMoney(scope.row.id, scope.row.store_name, scope.row.out_remark)"
              v-if="scope.row.status == 10 && scope.row.refund_pay_status == 2"
              >去打款</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="CurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
<!-- 退出入驻申请 -->
    <el-dialog
      title="退出入驻申请"
      :visible.sync="dialogShenHeShow"
      width="18%"
    >
      <div>
        <p>申请退出原因：</p>
        <div style="line-height: 26px;text-indent: 32px;margin-top: 5px">
          {{out_remark}}
        </div>
      </div>
      <p style="padding: 20px 0">
        是否同意门店<span style="color: #111">-{{ TCname }}-</span
        >的退出申请?
      </p>
       <p style="padding-bottom: 20px;">审核备注</p>
      <input type="text" v-model="remark" placeholder="请输入审核备注" style="width: 97%;">
      <!-- <input type="text" v-model="out_remarkPay" placeholder="请输入退款金额" style="width: 97%;"> -->
      <div class="txbtn" style="margin-top: 29px;">
        <el-button type="success" @click="RZsuccess">通过</el-button>
        <el-button type="danger" @click="RZerror">驳回</el-button>
        <el-button style="margin-left: 30%" @click="dialogShenHeShow = false"
          >取 消</el-button
        >
      </div>
      <!-- <span slot="footer" class="dialog-footer">
      </span> -->
    </el-dialog>
<!-- 退还保证金 -->
    <el-dialog
      title="退还保证金"
      :visible.sync="dialogPayShow"
      width="18%"
    >
      <p style="padding-bottom: 20px;">扣款原因</p>
      <input type="text" v-model="pay_out_remark" placeholder="请输入扣款原因,如未扣款输入:正常退款" style="width: 97%;">
      <p style="padding: 20px 0">退款金额</p>
      <input type="text" v-model="out_remarkPay" placeholder="请输入退款金额" style="width: 97%;">
      <div class="txbtn" style="margin-top: 29px;display: flex;justify-content: center;">
        <el-button type="success" @click="paySuccess">确定</el-button>
        <el-button style="margin-left: 30%" @click="dialogPayShow = false"
          >取 消</el-button
        >
      </div>
      <!-- <span slot="footer" class="dialog-footer">
      </span> -->
    </el-dialog>

    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>

        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportOK">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { store_settle_in_log, applyStoreSettleIn, storesettleinlogGoPay } from "@/api/api.js";
import { filenameFormat } from "@/utils/index";
export default {
  name: "storeDeposit",
  components: {},
  data() {
    return {
      tableData: [],
      form: {
        user_name: "",
        store_name: "",
        phone: "",
        start_time: "",
        end_time: "",
        status: "",
        is_settle_in: "",
        store_id: "", // 门店id
        out_end_time: "",
        out_start_time: '',
        limit: 10,
        partner_trade_no: '', // 退出单号
      },
      total: 0,
      TimeValue: null,
      TimeOutValue: null,
      dialogShenHeShow: false,
      dialogPayShow: false,
      TCid: "",
      TCname: "",
      exportShow: false,
      exportType: 1,
      pay_out_remark: '', //扣款原因
      out_remarkPay: '', //门店退出金额
      out_remark: '', //门店退出理由
      remark: '', //审核理由
    };
  },

  //计算属性 依赖缓存,多对一(即多个影响一个),不支持异步
  computed: {},
  //监控data中的数据变化,不依赖缓存,一对多,支持异步
  watch: {
    TimeValue: function (newVal, oldVal) {
      if (newVal != null) {
        console.log(this.form);
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    },
    TimeOutValue: function (newVal, oldVal) {
      if (newVal != null) {
        console.log(this.form);
        this.form.out_start_time = this.$timeTo.time4(this.TimeOutValue[0]);
        this.form.out_end_time = this.$timeTo.time4(this.TimeOutValue[1]);
      } else {
        this.form.out_start_time = "";
        this.form.out_end_time = "";
      }
    },
  },
  //方法集合
  methods: {
    CurrentChange(e) {
      this.form.page = e;
      this.getlist();
    },
    search() {
      this.form.page = 1;
      this.getlist();
    },

    // 导出订单
    exportShop() {
      this.exportShow = true;
    },
    // 取消导出
    exportShows() {
      this.exportShow = false;
    },
    // 确定导出
    exportOK() {
      this.exportShow = false;
      this.form.export = "export";
      this.form.export_type = this.exportType;
      console.log(this.form);
      this.getlist();
    },

    async getlist() {
      if (!this.form.export) {

        const { data } = await store_settle_in_log(this.form);
        if (data.code != 200) return this.$message.error(data.data);
        console.log(data);
        this.tableData = data.data.data;
        this.total = data.data.total;
        console.log(this.tableData);
        this.tableData.forEach((item) => {
          item.settle_in_time = this.$timeTo.time3(item.settle_in_time);
          if(item.refund_pay_time){
           item.refund_pay_time = this.$timeTo.time3(item.refund_pay_time);
          }
          item.out_time = this.$timeTo.time3(item.out_time);
        });
      } else {let { data, types, request } = await store_settle_in_log(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" });
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1]);
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition")
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        // console.log('filename====',filename)
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
        this.form.export = "";
      }
    },

    shenhe(id, store_name, out_remark) {
      this.dialogShenHeShow = true;
      this.TCid = id;
      this.out_remark = out_remark
      this.TCname = store_name;
    },
    async RZsuccess() {
      const { data } = await applyStoreSettleIn({ id: this.TCid, remark:this.remark, status: 10 });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.dialogShenHeShow = false;
    },
    async RZerror() {
      const { data } = await applyStoreSettleIn({ id: this.TCid, remark:this.remark, status: 20 });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.dialogShenHeShow = false;
    },
    
    //去打款
    toPlayMoney(id, store_name, out_remark){
      this.dialogPayShow = true;
      this.TCid = id;
      this.out_remark = out_remark
    },
    async paySuccess(){
      if(!this.out_remarkPay ){
        console.log('666666')
        return this.$message.error('请输入退款金额');
      }
      if(!this.pay_out_remark ){
        console.log('666666')
        return this.$message.error('请输入原因,未扣款填写:正常退款');
      }
      const { data } = await storesettleinlogGoPay({ id: this.TCid, money: this.out_remarkPay, pay_out_remark: this.pay_out_remark });
      if (data.code != 200) return this.$message.error(data.data);
      console.log(data)
      this.$message.success(data.data);
      this.out_remarkPay = ''
      this.remark = ''
      this.getlist();
      this.dialogPayShow = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.top {
  margin: 10px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
.el-dialog__body{
  padding: 0px 20px 30px 20px;
}
</style>